




import { Component, Prop, Vue } from 'vue-property-decorator'
import { VaccinationRegion, VaccinationRegionData } from '~base/common/types'

@Component
export default class VaccinationsPath extends Vue {
  @Prop({ type: Object, required: true }) readonly region!: VaccinationRegion
  @Prop({ type: Object, required: true })
  readonly regionData!: VaccinationRegionData

  get fillColor() {
    const colors = [
      { threshold: 45, color: '#007aff05' },
      { threshold: 50, color: '#007aff10' },
      { threshold: 51, color: '#007aff20' },
      { threshold: 52.5, color: '#007aff30' },
      { threshold: 54, color: '#007aff40' },
      { threshold: 58, color: '#007aff50' },
      { threshold: 62, color: '#007aff60' },
      { threshold: 66, color: '#007aff70' },
      { threshold: 70, color: '#007aff80' },
      { threshold: 75, color: '#007aff90' },
      { threshold: 80, color: '#007aff99' },
    ]

    let chosenColor = '#007aff05'
    for (const item of colors) {
      if (item.threshold > this.regionData.per100) break
      chosenColor = item.color
    }

    return chosenColor
  }
}
