












import { Vue, Prop, Component } from 'vue-property-decorator'
import { VaccinationRegion, VaccinationRegionData } from '~base/common/types'

@Component
export default class VaccinationsLabel extends Vue {
  @Prop({ type: Object, required: true }) readonly region!: VaccinationRegion
  @Prop({ type: Object, required: true })
  readonly regionData!: VaccinationRegionData
}
