











import { Component, Vue } from 'vue-property-decorator'
import Vaccinations from '~components/coronavirus/Vaccinations.vue'
import { Page } from '~base/common/types'

@Component({
  components: { Vaccinations },
  apollo: {
    pages: {
      query: require('~base/graphql/queries/PageBySlug.gql'),
      variables: { page_slug: 'covid-impftracker' },
    },
  },
})
export default class CoronaVaccinations extends Vue {
  private pages: { nodes?: Page[] } | undefined
  get page() {
    return this.pages?.nodes?.[0]
  }

  get title() {
    return this.page ? this.page.title : `Corona Impftracker`
  }

  get content() {
    return this.page ? this.page.content : ``
  }
}
