


























import { Vue, Prop, Component } from 'vue-property-decorator'
import { VaccinationRegionData } from '~base/common/types'

@Component
export default class VaccinationsLabelTotal extends Vue {
  @Prop({ type: Object, required: true })
  readonly regionData!: VaccinationRegionData
}
